
import './navbarhome.css';

import React, { useState,useEffect } from 'react';

import {signOut, getProfilePicUrl, getUserName, isUserSignedIn} from './firebase_login';
import getFirebase from './firebase';

import {Link} from 'gatsby';
const firebase = getFirebase();

function Navwork(props) {

      useEffect(() => {

              if (!firebase) return;

        // document.getElementById('href2').href = window.location.origin

            //   const price_path = window.location.origin + '/pricing'
              const myvideos_path = '/workspace/?w=' + localStorage.getItem('uid')
              
              
              const pic = localStorage.getItem('pic')
              console.log('PICTURE', pic);



        document.getElementById("navbardrop").src = pic;
        // document.getElementById("upgr").href = price_path;

        if(localStorage.getItem('cn') == '0' ) {
        document.getElementById('upgr').innerText = 'UPGRADE'
        // document.getElementById('upgr').style.backgroundColor = 'yellow'
      }
      


      document.getElementById('close_signout').addEventListener('click', (e) => {

        e.preventDefault();
        e.stopPropagation();

        document.getElementById('drop_snox').style.display = 'block'
        document.getElementById('snox').style.display = 'block'
        
})
      
      document.getElementById('snox').addEventListener('click', (e) => {

        e.preventDefault();
        e.stopPropagation();

        signOut(firebase)
    });

      },)


	return (
		<div>


		<nav className="navbar navbar-inverse navbar-fixed-top home-fixed-top" role="navigation">


        <div className="navbar-header">
           
            <div className="navbar-brand">
                <Link className="CB" to="/">TurtleClip</Link>
            </div>
            
            <div style={{'display':'flex'}}>
              <div className="home-list workspace_list">
                  <Link id='upgr' to="/pricing">PRICING</Link>
              </div>
                  
              <div className="nav-item dropdown" id='close_signout'>

               <img className="nav-link dropdown-toggle" alt="Account" id="navbardrop" data-toggle="dropdown">                   
               </img>

               <div class="dropdown-menu" id='drop_snox'>
                  <div id='profile_name'></div>
                  <a id='snox' class="signout dropdown-item">Sign Out</a>
               </div>
              
              </div>
            </div>

        </div>
</nav>


		</div>

		)
}


Navwork.displayName = "Navwork";
export default React.memo(Navwork);
