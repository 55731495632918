import React from 'react';

/* global $ */


// import Video2 from './video2';
// import Nav from './navbar';
import Navwork from '../components/navbarwork';

import {load_workspace} from '../components/workspace_util';

import {flask_multipart} from '../components/flask-s3-multipart';
import '../components/flask_multipart.css';

import '../components/videos.css'

import axios from 'axios'; 

import {login, getProfilePicUrl, getUserName, isUserSignedIn, confirmEmail} from '../components/firebase_login';

import getFirebase from '../components/firebase'; // import our getFirebase function

import {connect} from 'react-redux';
import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction";


import CircularProgress from '@material-ui/core/CircularProgress';

import { navigate } from "gatsby"  


class Workspace extends React.Component {

constructor(props) {
    super(props);

this.state =   {
      // duration: "33.9",
      // uid: props.location.state.videos.uid,
      // uid: localStorage.getItem('uid'),

      // paths: props.location.state.videos.paths,
      // vids: props.location.state.videos.vids,
      // scripts: props.location.state.videos.scripts,
      // file: 1, 
      // vid:1, 
      // meta: 1,
      // head: 1, 
      // duration: 1, 
      // // ar: 1, 
      // v:1,
      // render:'',
      // color:1,

}

// this.showVideo = this.showVideo.bind(this);


}

// showVideo(e) {

// document.getElementById("circleDown").style.display = 'block'
// document.getElementById("works").style.display = 'none'
// // 	var i = e.target.id 

// // 	var file = this.state.paths[i].split('/')[1] 
	
// // 	var meta = this.state.scripts[i]
// // 	var vid = this.state.paths[i]

// // 	var head = this.state.vids[i.toString()]['head']
// // 	var ar = this.state.vids[i.toString()]['ar']
// // 	var v = this.state.vids[i.toString()]['v']
// // 	var duration = this.state.vids[i.toString()]['duration']

// //     var data = {"file": file, 'vid':vid, "meta": meta,"head": head, "duration": duration, "ar": ar, "v":v}

// //     this.props.history.push({
// //   		pathname: `/video/${data["file"]}`,
// //   		state: { vid: data }
// // 	})

// console.log('e.target', e.target)
// // get the json, push it to localstorage
// var fname = e.target.src.split('.net/')[1].split('.')[0] + '.json'

// console.log('fname', fname)
// // 'https://d2wxuz9jomxjzh.cloudfront.net/' + fname

// var url = 'https://la04iscxs9.execute-api.us-west-2.amazonaws.com/default/json_from_s3?key=' + fname

// console.log('url', url)

// axios.get(url).then((res) => {
//    console.log('GET', res.data)
// var x = JSON.parse(res.data)

// console.log('X',x)
// var ks = Object.keys(x)
// console.log('Keys',ks)

// var uid = localStorage.getItem("uid")
// var pic = localStorage.getItem("pic")
// var subscribe = localStorage.getItem("cn")
// var email = localStorage.getItem("email")

// localStorage.clear()


// if(uid) localStorage.setItem("uid", uid)
// if(pic) localStorage.setItem("pic", pic)
// if(subscribe) localStorage.setItem("cn", subscribe)
// if(email) localStorage.setItem('email', email)


// for (var i=0; i<ks.length; i++) {

//     localStorage.setItem(ks[i], x[ks[i]])
// }


// var u = localStorage.getItem('uuid')

// // document.getElementById("circleDown").style.display = 'none'
// // document.getElementById("works").style.display = 'block'

// // this.props.history.push({
// //     pathname: `/vidx/${u}`,
// //     state: { vid: x }
// // })
// const namef = fname.split('.')[0] 
// // + '-' + fname.split('.')[1]

// navigate(`/vidx/?v=${namef}`, {
//   state: { vid: x }
// })

// }).catch(err => {

//    document.getElementById("circleDown").style.display = 'none'
//    document.getElementById("works").style.display = 'block'
   
//    console.log('json GET ERROR', err)

// })



// }





componentDidMount() {

        console.log(this.props)

      
        document.title = 'TurtleClip | Videos';

        let firebase = getFirebase();

        var uid = localStorage.getItem("uid")

        document.getElementById('works').style.display = 'none'

        document.getElementById("circleDown").style.display = 'block';

        try {
        // document.getElementById("navbardrop").src = localStorage.getItem('pic');

} catch(e) {console.log('NOT SIGNED IN YET', e)}

        let check1 = uid && window.location.search == '?w=' + uid
        let check2 = 0

// this get called on confirm email?
// on reload
  if( check1 ) {

      load_workspace(this.props)

  } 
// if check1 is not true, then check for email login
  else {
    // when login is using email Link
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);

    console.log('urlParams', urlParams)

    check2 = urlParams.get('apiKey') && urlParams.get('apiKey') == process.env.GATSBY_REACT_APP_FIREBASE_KEY



    // if this fails, dont open the workspace na!
    if(check2) {

        var href_sign = window.location.href

        // if confirm email, load workspace
        window.history.replaceState({}, document.title, window.location.pathname);

        confirmEmail(firebase, this.props, href_sign)
        
    } else {

    document.getElementById("circleDown").style.display = 'none'
    navigate('/')

    }

}


//close if-else 


}




render () {
    // console.log('common file sending state to vid/tab', this.state.sub);
  return (
    <div id='workit' style={{'position': 'absolute', 'left':'0', 'right':'0'}}>

    <CircularProgress id="circleDown"/>
<div id='works'>
  		
<Navwork />

<div className="workspace_upload">

  <div className="up-try">
    <div className="file-chooser">
      <label for="file-workspace" className="custom-file-upload">
          Upload
      </label>
      <input type="file" id='file-workspace' accept=".mp4,.m4v,.mov,video/mp4,video/x-m4v,video/m4v/video/mov" />
    </div>

    <div id="file-progress-container"></div>
    <script src="md5.js"></script>
    <script src="flask-s3-multipart.js"></script>
  </div>

</div>

<div className='remV' id='prx'> Videos Remaining: <span id='rem'/></div>  		
<div id="rx"></div>
		
		</div>

  </div>
	)
}
}


const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}


Workspace.displayName = "Workspace";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Workspace));
